/**
* Setup webpack public path
* to enable lazy-including of
* js chunks
*
*/
/* ----global urls:true---- */
import 'bootstrap';
import 'waypoints/lib/jquery.waypoints';
import 'waypoints/lib/shortcuts/inview';
import 'slick-carousel';

import initGoogleMap from './scripts/google-maps';
import { anchorOnClick, anchorOnLoad } from './utils/anchor-scroll';

const $ = window.jQuery;

$(() => {
    // Anchor scroll
    const scrollOffset = 100;
    anchorOnClick(scrollOffset, 500);
    anchorOnLoad(scrollOffset, 500);

    // Add class when inview
    $('.fadein, .text-image__text, .draw-border').each((index, item) => {
        $(item).addClass('ready');

        new window.Waypoint.Inview({
            element: $(item),
            enter: () => {
                $(item).addClass('inview');

                if ($(item).hasClass('fadein--saturation')) {
                    setTimeout(function() {
                        $(item).removeClass('inview');
                    }, 1000);
                }
            },

        });
    });

    // Add class to Navbar when scroll from top
    $('body').waypoint({
       handler: direction => {
           if (direction === 'up') {
               $('.navbar').removeClass('navbar--sticky');
           } else {
               $('.navbar').addClass('navbar--sticky');
           }
       },
        offset: '-100px'
    });

    $('.carousel__carousel').on('init reInit afterChange', (event, slick, currentSlide, nextSlide) => {
        const i = (currentSlide ? currentSlide : 0) + 1;
        $('.carousel__nav .count').text(`${i}/${slick.slideCount}`);
    });

    // Init carousel
    const carousel = $('.carousel__carousel').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        appendArrows: '.carousel__nav .next',
        prevArrow: '',
        nextArrow: '<a class="slick-next carousel__next"><i class="far fa-arrow-right"></i></a>',
    });

    const centeredCarousel = $('.centered-carousel').slick({
        centerMode: true,
        arrows: false,
        dots: false,
        slidesToShow: 3,
        autoplay: true,
        speed: 10000,
        autoplaySpeed: 0,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

});

$(window).resize(() => {

});

if (!('initMap' in window)) {
    window.initMap = initGoogleMap;
}

