import { isMobile } from '../utils/detect-browser';

const $ = window.jQuery;

export default function () {
	const mapContainer = document.getElementById('map');
	const { google } = window;
	const latPt = -16.918570;
	const longPt = 145.775020;
	const markerPath = `${$('#map').data('path')}/assets/img/building-icon.png`;

	const mapStyle = [
		{
			elementType: 'geometry',
			stylers: [
				{ color: '#f5f5f5' }
			]
		},
		{
			elementType: 'labels.icon',
			stylers: [
				{ visibility: 'off' }
			]
		},
		{
			elementType: 'labels.text.fill',
			stylers: [
				{ color: '#616161' }
			]
		},
		{
			elementType: 'labels.text.stroke',
			stylers: [
				{ color: '#f5f5f5' }
			]
		},
		{
			featureType: 'administrative.land_parcel',
			elementType: 'labels.text.fill',
			stylers: [
				{ color: '#bdbdbd' },
				{ lightness: 100 },
				{ visibility: 'off' }
			]
		},
		{
			featureType: 'poi',
			elementType: 'geometry',
			stylers: [
				{ color: '#eeeeee' }
			]
		},
		{
			featureType: 'poi',
			elementType: 'labels.text.fill',
			stylers: [
				{ color: '#757575' }
			]
		},
		{
			featureType: 'poi.business',
			stylers: [
				{ visibility: 'off' }
			]
		},
		{
			featureType: 'poi.park',
			elementType: 'geometry',
			stylers: [
				{ color: '#e5e5e5' }
			]
		},
		{
			featureType: 'poi.park',
			elementType: 'labels.text',
			stylers: [
				{ visibility: 'off' }
			]
		},
		{
			featureType: 'poi.park',
			elementType: 'labels.text.fill',
			stylers: [
				{ color: '#9e9e9e' }
			]
		},
		{
			featureType: 'road',
			elementType: 'geometry',
			stylers: [
				{ color: '#ffffff' }
			]
		},
		{
			featureType: 'road',
			elementType: 'geometry.fill',
			stylers: [
				{ color: '#de543e' },
				{ lightness: 50 },
			]
		},
		{
			featureType: 'road.arterial',
			elementType: 'labels.text.fill',
			stylers: [
				{ color: '#757575' }
			]
		},
		{
			featureType: 'road.highway',
			elementType: 'geometry',
			stylers: [
				{ color: '#dadada' }
			]
		},
		{
			featureType: 'road.highway',
			elementType: 'labels.text.fill',
			stylers: [
				{ color: '#616161' }
			]
		},
		{
			featureType: 'road.local',
			elementType: 'labels.text.fill',
			stylers: [
				{ color: '#9e9e9e' }
			]
		},
		{
			featureType: 'transit.line',
			elementType: 'geometry',
			stylers: [
				{ color: '#e5e5e5' }
			]
		},
		{
			featureType: 'transit.station',
			elementType: 'geometry',
			stylers: [
				{ color: '#eeeeee' }
			]
		},
		{
			featureType: 'water',
			elementType: 'geometry',
			stylers: [
				{ color: '#c9c9c9' }
			]
		},
		{
			featureType: 'water',
			elementType: 'labels.text.fill',
			stylers: [
				{ color: '#9e9e9e' }
			]
		}
	];

	if (mapContainer) {
		/* eslint-disable no-unused-vars */
		const map = new google.maps.Map(mapContainer, {
			center: {lat: latPt, lng: longPt},
			zoom: 17,
			styles: mapStyle,
			disableDefaultUI: true
		});

		const markerImage = {
			url: markerPath,
			size: new google.maps.Size(150, 116),
			scaledSize: new google.maps.Size(40, 46.4),
			origin: new google.maps.Point(0, 0),
			anchor: new google.maps.Point(30, 70),
			optimzed: false
		};

		const mapMarker = new google.maps.Marker({
			position: {lat: latPt, lng: longPt},
			map,
			icon: markerImage
		});


		if (isMobile()) {
			map.setOptions({draggable: false});
		}
	}
}
